import React from 'react';
import Container from 'components/Container/Container';
import ContactPage from 'components/ContactPage/ContactPage';

class Contact extends React.Component {
  render() {
    return (
      <Container size="sm">
        <ContactPage />
      </Container>
    );
  }
}

export default Contact;
