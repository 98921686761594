import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ArrowIcon from 'assets/images/Icons/arrow.svg';
import './MoreButton.scss';

const MoreButton = ({ text, link, href, onClick }) => {
  if (link !== null) {
    return (
      <Link to={link} className="MoreButton">
        <div className="MoreButton-circle">
          <div className="MoreButton-text">
            <h6>{text}</h6>
            <img src={ArrowIcon} />
          </div>
        </div>
      </Link>
    );
  }

  return (
    <a href={href} onClick={onClick} target="_blank" className="MoreButton">
      <div className="MoreButton-circle">
        <div className="MoreButton-text">
          <h6>{text}</h6>
          <img src={ArrowIcon} />
        </div>
      </div>
    </a>
  );
};

MoreButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  href: PropTypes.string,
};

MoreButton.defaultProps = {
  link: null,
  href: null,
};

export default MoreButton;
