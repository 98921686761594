import React from 'react';
import MoreButton from 'components/MoreButton/MoreButton';
import { formEntry } from 'constants/form';
import { emailIsValid } from 'utils/form';
import { getLangFile } from 'utils/lang';
import './ContactForm.scss';

class ContactForm extends React.Component {
  state = {
    [formEntry.fullName]: { value: '' },
    [formEntry.email]: {
      value: '',
      noValue: false,
      notValid: false,
    },
    [formEntry.company]: { value: '' },
    [formEntry.projectDetails]: {
      value: '',
      noValue: false,
    },
  };

  onInputValueChange = (e, key) => {
    this.setState({
      [key]: {
        value: e.target.value,
      },
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { onValidated, onSubmitted } = this.props;
    const isInValid = this.onValidateInput();

    if (isInValid) return;

    onValidated({
      [formEntry.email]: this.state[formEntry.email].value,
      [formEntry.fullName]: this.state[formEntry.fullName].value,
      [formEntry.company]: this.state[formEntry.company].value,
      [formEntry.projectDetails]: this.state[formEntry.projectDetails].value,
    });

    onSubmitted();
  };

  onValidateInput = () => {
    const email = this.state[formEntry.email];
    const projectDetails = this.state[formEntry.projectDetails];

    if (!email.value) {
      this.onInvalid('noValue', formEntry.email);
    } else if (!emailIsValid(email.value)) {
      this.onInvalid('notValid', formEntry.email);
    }

    if (!projectDetails.value) {
      this.onInvalid('noValue', formEntry.projectDetails);
      return false;
    }

    this.setState({
      email: {
        noValue: false,
        notValid: false,
      },
    });

    return false;
  };

  onInvalid = (validKey, inputKey) => {
    const inputValue = this.state[inputKey];

    this.setState({
      [inputKey]: {
        ...inputValue,
        [validKey]: true,
      },
    });
  };

  render() {
    const file = getLangFile();
    const t = file.contactPage;
    const { message, status } = this.props;
    const email = this.state[formEntry.email];
    const projectDetails = this.state[formEntry.projectDetails];

    return (
      <div className="ContactForm">
        <input
          onChange={e => this.onInputValueChange(e, formEntry.company)}
          placeholder={t.company}
          type="text"
        />

        {email.noValue && <div className="ContactForm-required">{t.required}</div>}
        {email.notValid && <div className="ContactForm-required">{t.emailNotValid}</div>}
        <input
          onChange={e => this.onInputValueChange(e, formEntry.email)}
          placeholder={t.email}
          type="text"
        />

        <input
          onChange={e => this.onInputValueChange(e, formEntry.fullName)}
          placeholder={t.name}
          type="text"
        />

        {projectDetails.noValue && <div className="ContactForm-required">{t.required}</div>}
        <textarea
          onChange={e => this.onInputValueChange(e, formEntry.projectDetails)}
          placeholder={t.project}
          name="message"
          rows="10"
          cols="30"
        />
        <div>
          <MoreButton onClick={this.onSubmit} text={t.submit} />
        </div>
        <div className="ContactForm-msg">
          {status === 'sending' && <div className="ContactForm-msg--sending">sending...</div>}
          {status === 'error' && (
            <div className="ContactForm-msg--error" dangerouslySetInnerHTML={{ __html: message }} />
          )}
          {status === 'success' && (
            <div className="ContactForm-msg--success">
              Successfuly sent, please check your inbox.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ContactForm;
