const config = {
  //change the config to your firebase project
  apiKey: 'AIzaSyCbVH2HB1Jm87ImK-DPUteygFcxrmt_o2E',
  authDomain: 'vrizead.firebaseapp.com',
  databaseURL: 'https://vrizead.firebaseio.com',
  projectId: 'vrizead',
  storageBucket: 'vrizead.appspot.com',
};

let firebaseInstance;

export const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance;
  }

  firebase.initializeApp(config);
  return firebase;
};
